import { Pipe, PipeTransform } from '@angular/core';
import {Observable} from "rxjs";

@Pipe({
  name: 'filter',
    pure: false

})
export class FilterPipe implements PipeTransform {

  transform(value: any[], filters: any[], propName:string): any {
      console.log(filters);
      console.log(propName);
      console.log(value);

      const filterStrs: string[] = filters.map(x => x.name);

      if (value.length === 0 || filters.length === 0) {
          return [];
      }
      const resultArray = [];
      for (const item of value) {
          console.log(item);

          if (item[propName] != null && item[propName].some(r => filterStrs.includes(r))) {
              resultArray.push(item);
          }

      }
      return resultArray;

  }

}
