import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-mainview',
  templateUrl: './mainview.component.html',
  styleUrls: ['./mainview.component.scss']
})


export class MainviewComponent implements OnInit {
   public results1$;
  public results2$;

  tiles = [
    {text: 'about me', img:"assets/img/robert-jan.png", cols: 2, rows: 3, color: '#607D8B', textcolor:'white', imgwidth:50, target:'/about-me'},
    {text: '', desc:'', img:"assets/img/publications.jpg", cols: 1, rows: 1, color: 'white', textcolor:'black', imgwidth:100, target:'/publications'},
    {text: '', desc:'', img:"assets/img/press.png", cols: 1, rows: 1, color: 'lightpink', textcolor:'black',  imgwidth:100, target:'/press'},
    {text: '', desc:'', img:"assets/img/Slide1.png", cols: 1, rows: 1, color: '#DDBDF1', textcolor:'black',  imgwidth:100, target:'/talks'},
  ];

  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Card 1', cols: 1, rows: 1 },
          { title: 'Card 2', cols: 1, rows: 1 },
          { title: 'Card 3', cols: 1, rows: 1 },
          { title: 'Card 4', cols: 1, rows: 1 }
        ];
      }

      return [
        { title: 'Card 1', cols: 2, rows: 1 },
        { title: 'Card 2', cols: 1, rows: 1 },
        { title: 'Card 3', cols: 1, rows: 2 },
        { title: 'Card 4', cols: 1, rows: 1 }
      ];
    })
  );

  constructor(private breakpointObserver: BreakpointObserver, private titleService: Title) {}

  ngOnInit(){
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.results1$=true;
          console.log(
            'Matches small viewport or handset in portrait mode'
          );
        }
      });
    this.titleService.setTitle('Robert-Jan Sips');

    //this.results1$ = this.breakpointObserver.observe('(max-width: 350px)')
    //console.log(this.results1$)
    //this.results2$ = this.breakpointObserver.observe(['(max-width: 350px)', '(max-width: 450px)'])
  }



}
