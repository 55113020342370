import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {ContactService} from "../contact.service";
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.scss']
})
export class ContactformComponent implements OnInit {

  FormData: FormGroup;

  constructor(private builder: FormBuilder, private contact: ContactService, private _bottomSheetRef: MatBottomSheetRef<ContactformComponent>) {
  }

  ngOnInit() {
    this.FormData = this.builder.group(
        {
          Fullname: new FormControl('', [Validators.required]),
          Email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
          Comment: new FormControl('', [Validators.required])
        })
  }

  onSubmit(FormData) {
    console.log(FormData)
    this.contact.PostMessage(FormData)
        .subscribe(
            response => {
                location.href = 'https://mailthis.to/confirm'
                this._bottomSheetRef.dismiss();
                event.preventDefault();
            },
            error => {
              console.warn(error.responseText)
              console.log({error})
            })


  }
}
