import {Component, OnInit} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  collapseAnimation,
  rubberBandAnimation,
  bounceOutOnLeaveAnimation,
  bounceInOnEnterAnimation,
    flipInYOnEnterAnimation,
    flipOutYOnLeaveAnimation,
    rotateInOnEnterAnimation,
    rotateOutOnLeaveAnimation
} from 'angular-animations';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ContactformComponent} from "../contactform/contactform.component";

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  animations: [ rubberBandAnimation(),
    collapseAnimation(), bounceInOnEnterAnimation(), bounceOutOnLeaveAnimation(), flipInYOnEnterAnimation(), flipOutYOnLeaveAnimation(), rotateInOnEnterAnimation(), rotateOutOnLeaveAnimation()
  ]
})




export class MainNavComponent  {

  isHamburguer = true;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private _bottomSheet: MatBottomSheet) {

  }

  onToggleFab(){
    this.isHamburguer = !this.isHamburguer;
    this._bottomSheet.open(ContactformComponent);
  }


}
