<div style="width:100%; height:calc(100% - 64px);">
       <!--nav aria-label="breadcrumb" style="position:absolute; top:0px; left:50px; z-index: 1000;">
                  <ul class="breadcrumb">
                    <li class="breadcrumb-item" aria-current="page">Robert-Jan Sips</li>
                  </ul>
                </nav-->
  <mat-grid-list cols="{{this.results1$?1:3}}" rowHeight="fit" style="width:100%; height:100%;">
    <mat-grid-tile
        *ngFor="let tile of tiles"
        [colspan]="this.results1$?1:tile.cols"
        [rowspan]="this.results1$?1:tile.rows"
        [style.background]="tile.color"
        [style.color]="tile.textcolor">
        <a routerLink="{{tile.target}}">
            <table>
              <tr><td><img src="{{tile.img}}" width="100%" /></td></tr>
              <tr><td><h2>{{tile.desc}}</h2><h2>{{tile.text}}</h2></td></tr>
            </table>
        </a>
    </mat-grid-tile>
  </mat-grid-list>
</div>
