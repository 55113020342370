    <div class="md-fab-bottom-right3">
        <a  routerLink="" mat-fab  aria-label="" color="primary">
              <mat-icon>arrow_back</mat-icon>
        </a>
      </div>


<div style="width:100%; height:calc(100% - 64px); overflow:scroll;">
  <mat-grid-list cols="{{this.results1$?1:4}}" rowHeight="fit" style="width:100%; height:100%; overflow:scroll;">
      <mat-grid-tile  *ngIf="this.results1$==false"  [colspan]="1" [rowspan]="1" [style.background]="'lightsteelblue'">

            <!--nav aria-label="breadcrumb" style="position:absolute; top:0px; left:50px;">
                  <ul class="breadcrumb">
                    <li class="breadcrumb-item" aria-current="page"><a routerLink="">Robert-Jan Sips</a></li>
                     <li class="breadcrumb-item active" aria-current="page">Publications</li>

                  </ul>
                </nav-->
           <table style="width:50%;">
              <tr><td><img src="assets/img/robert-jan.png" width="100%" alt="Robert-Jan Sips" /></td></tr>
              <tr><td><h1>Publications</h1></td></tr>
              <tr><td>
                  Throughout my career I've made an effort to build systems that challenge state-of-the-art or provide new ways to solve real-world problems.

                  Doing so, I've setup collaborations with academia, using the enterprise as an experimental ground and encouraged the teams I worked with to publish their work in peer-reviewed venues and to open datasets.

                  On this page an overview of the publications I (co-)authored.
              </td></tr>
          </table>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="this.results1$?1:3" [rowspan]="1" >
          <div fxLayout="column" fxLayoutGap="32px" style="width:100%; height:100%; overflow:scroll; padding-top:50px; margin-left:50px;">

               <mat-chip-list [(ngModel)]="tags" multiple>
                <mat-chip *ngFor="let tag of tags" selectable="true"
                         [selected]="tag.selected"
                         (click)="toggleTag(tag)"
                        class="{{isSelected(tag) ? 'mat-chip-selected' : ''}}">
                  {{tag.name}}&nbsp;
                  <mat-icon>{{isSelected(tag) ? "close" : "add"}}</mat-icon>
                </mat-chip>

              </mat-chip-list>

            <mat-card *ngFor="let publication of Publications  |  filter:selectedTags2:'tags' " class="example-card" style="width:80%; padding-left:10%;" >

               <mat-card-header>
                    <img mat-card-avatar *ngIf="publication.image" src="{{publication.image}}"  />
                    <mat-card-title class="title">{{publication.title}}</mat-card-title>


               </mat-card-header>
                <mat-card-content >
                        <mat-expansion-panel class="mat-elevation-z0">

                        <mat-expansion-panel-header class="right-aligned-header" style="width:120px; color:steelblue;">
                            <span>read abstract</span>
                        </mat-expansion-panel-header>
                        <mat-card-subtitle>{{publication.authors.join(", ")}}</mat-card-subtitle>
                       {{publication.description}}
                      </mat-expansion-panel>

                    <div class="flex-container"
                        fxLayout="row"
                        fxLayoutAlign="space-between center"
                    >
                      <div class="flex-item">
                         Full article: <a mat-button href="{{publication.link}}">{{publication.reference}}</a>
                      </div>
                      <div class="flex-item">
                        {{publication.tags.join(", ")}}
                      </div>

                    </div>



                </mat-card-content>


                <!--mat-card-header>
                  <mat-card-title class="title">{{publication.title}}</mat-card-title>
                  <mat-card-subtitle>{{publication.authors.join(", ")}}</mat-card-subtitle>
                  <mat-card-subtitle>{{publication.reference}}</mat-card-subtitle>


                </mat-card-header>
                <mat-card-content>
                   <mat-expansion-panel class="mat-elevation-z0" style="width:75%;">
                        <mat-expansion-panel-header style="text-align:left; flex:0;">
                            read abstract
                        </mat-expansion-panel-header>
                       {{publication.description}}
                      </mat-expansion-panel>

                </mat-card-content>
                <mat-card-actions class="action-buttons">
                                          <a mat-button href="{{publication.link}}">read more</a>

                </mat-card-actions -->
              </mat-card>

          </div>
      </mat-grid-tile>
  </mat-grid-list>
</div>



