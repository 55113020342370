
                <form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">
                    <p>
                      <mat-form-field appearance="standard">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Full name" name="Fullname" formControlName="Fullname">
                      </mat-form-field>
                    </p>

                    <p>
                      <mat-form-field appearance="standard">
                        <mat-label>E-mail</mat-label>
                        <input matInput placeholder="E-mail" name="Email" formControlName="Email">
                        </mat-form-field>
                    </p>

                    <p>
                      <mat-form-field appearance="standard">
                        <mat-label>What can I do for you</mat-label>
                          <textarea matInput placeholder="Comment" name="Comment" formControlName="Comment"></textarea>
                      </mat-form-field>
                    </p>

                <button mat-raised-button [disabled]="!FormData.valid" type="submit" >Submit</button>

                </form>


