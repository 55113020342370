    <div class="md-fab-bottom-right3">
        <a  routerLink="" mat-fab  aria-label="" color="primary">
              <mat-icon>arrow_back</mat-icon>
        </a>
      </div>

<div style="width:100%; height:calc(100% - 64px);">
  <mat-grid-list cols="{{this.results1$?1:4}}" rowHeight="fit" style="width:100%; height:100%;">
      <mat-grid-tile *ngIf="this.results1$==false" [colspan]="1" [rowspan]="1" [style.background]="'#607D8B'">
              <!--nav  aria-label="breadcrumb" style="position:absolute; top:0px; left:50px;">
                  <ul class="breadcrumb">
                    <li class="breadcrumb-item" aria-current="page"><a routerLink="">Robert-Jan Sips</a></li>
                     <li class="breadcrumb-item active" aria-current="page">About me</li>

                  </ul>
                </nav-->
          <table style="width:50%; color:white;">
              <tr><td><img src="assets/img/robert-jan.png" width="100%" alt="Robert-Jan Sips" /></td></tr>
              <tr><td><h1>About me</h1></td></tr>
              <tr><td>I have 15 years of experience applying Artificial Intelligence to real-life problems, such as pharma, medicine, finance, automotive and smart grid.
                  I am a technology enthousiast at heart and love to find technological solutions to hard problems.
                  At IBM, I led the Benelux Center for Advanced Studies in the Benelux and later Europe; where we partnered with industry and academia, to bring new technology to the problems of industry. Next to that, I  performed fundamental research on the domain adaptation IBM Watson.
                  I love teaching and am a regular (guest) speaker on AI.
              </td></tr>
          </table>
      </mat-grid-tile>
          <mat-grid-tile [colspan]="this.results1$?1:3" [rowspan]="1">

              <mat-tab-group mat-stretch-tabs animationDuration="2000ms" style="width:100%; height:100%; top:0;">
                  <mat-tab label="Professional">

                      <mat-card fxLayout fxLayoutAlign="space-between">
                         <div  fxLayout="column" fxLayoutGap="30px" fxFlex="1 1 auto"> <!--*ngFor="let article of mArticles"-->

                             <!-- div fxFlex="1 1 20%" -->
                             <img src="assets/img/professional/tkh.png"  height="50px" width="100px" />
                             <!-- /div -->

                               <!--div  fxLayout="column" fxLayoutGap="30px" fxFlex="1 1 auto" --> <!--*ngFor="let article of mArticles"-->
                                   <mat-card>
                                   <mat-card-header>

                                     <mat-card-title class="title">Director Smart Software & AI</mat-card-title>
                                     <mat-card-subtitle>2021 to date</mat-card-subtitle>
                                   </mat-card-header>
                                   <mat-card-content>
                                     <p>
                                      At TKH, an international group of technology companies, I am responsible for the group-wide adoption and acceleration of smart software and artificial intelligence. 
                                      </p>

                                   </mat-card-content>
                                   <mat-card-actions class="action-buttons">
                                   </mat-card-actions>
                                 </mat-card>

                          <!-- div fxFlex="1 1 20%" -->
                                <img src="assets/img/professional/myTomorrows.png"  height="50px" width="200px;" />
                              <!-- /div -->

                                <!--div  fxLayout="column" fxLayoutGap="30px" fxFlex="1 1 auto" --> <!--*ngFor="let article of mArticles"-->
                                    <mat-card>
                                    <mat-card-header>

                                      <mat-card-title class="title">Chief Technology Officer</mat-card-title>
                                      <mat-card-subtitle>2017-2021</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content>
                                      <p>
                                        As CTO, I am leading a team of 20 data scientists and engineers, with whom we jointly pushing the boundaries of technology, to help those with unmet medical need.

                                       Main achievements:
                                          <ul>
                                        <li>Recruited a product, engineering and data science team</li>
                                        <li>Initiated collaboration with leading academia (Delft university, Erasmus medical center)</li>
                                        <li>Led the company towards GDPR compliance</li>
                                        <li>Architected a central CRM System and standard tools for BI / metrics</li>
                                        <li>Implemented an AI roadmap</li>
                                        <li>Performed a vendor selection and negotiations for our CRF tool, enabling our RWD collection service</li>
                                         <li>Led the build of a state-of-the-art semantic search engine for clinical trial</li>
                                    </ul>



                                    </mat-card-content>
                                    <mat-card-actions class="action-buttons">
                                    </mat-card-actions>
                                  </mat-card>

                                  <mat-card>
                                    <mat-card-header>

                                      <mat-card-title class="title">Chief Marketing Officer (interim)</mat-card-title>
                                      <mat-card-subtitle>2018-2019</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content>
                                      <p>
                                As interim CMO, I recruited a team of 5, including a marketing director, and defined the overall digital marketing strategy for myTomorrows.

                                Main achievements:
                                        <ul><li>Setup a paid and unpaid channel strategy, moving paid from Social to SEA.</li>
                                        <li>Implemented a proprietary machine learning algorithm, scaling our SEA efforts to hundreds of campaigns in >26 countries.</li>
                                        <li>Setup of a Patient Advocacy group partnership program and social media strategy, centering on the stories behind myTomorrows (employees, patients, caregivers).</li>
                                        <li>Implementation of a new website and automated, standardized landing pages.</li>
                                        <li>Setup of a SEO strategy, centering around structured data (Schema.org) and link building.</li></ul>

                                    </mat-card-content>
                                    <mat-card-actions class="action-buttons">
                                    </mat-card-actions>
                                  </mat-card>

                                </div>
                        </mat-card>

                        <mat-card fxLayout fxLayoutAlign="space-between">
                         <div  fxLayout="column" fxLayoutGap="30px" fxFlex="1 1 auto"> <!--*ngFor="let article of mArticles"-->
                                <img src="assets/img/professional/ibm_logo.png"  height="50px" width="100px"/>

                                    <mat-card>
                                    <mat-card-header>

                                      <mat-card-title class="title">European Manager</mat-card-title>
                                      <mat-card-subtitle>IBM CAS and University Programs, 2016-2017</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content>
                                      <p>
                                Responsible	for	the	strategy	of	Centers	for	Advanced	Studies	in	Europe	and	Central
Asia	(27	countries,	70	fte).	In	this	role	I	was	responsible	for	the	transformation	of
these	 academic	 collaboration hubs into	 self-funding AI	 Innovation	 centers,
translating	 AI	research	into	real-world	business	solutions for	IBMs	clients.</p>
                                    </mat-card-content>
                                    <mat-card-actions class="action-buttons">

                                    </mat-card-actions>
                                  </mat-card>

                                      <mat-card>
                                    <mat-card-header>

                                      <mat-card-title class="title">Belgium, Netherlands, Luxembourg Manager</mat-card-title>
                                      <mat-card-subtitle>IBM CAS and University Programs, 2015-2017</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content>
                                      <p>
                                Responsible	for	 the	day-to-day	 operation and	 strategy	 of	 IBM	Benelux	Center	for
Advanced	 Studies.	 In	 this	 role	 I	 restructured	 the	 Benelux	 CAS	 into	 a	 profitable
commercial	AI	Innovation department,	with	 offices	in	Amsterdam	 and	Brussels. I
founded	Collaborative	Innovation	Centers with	VU	University	Amsterdam	and	Delft
University	of	Technology,	allowing	academia	and	IBM	clients	to	work	together on	AI
business	 applications.	 Finally,	 we	 created	 AI	masterclasses	 for	 professionals	 and
university	students,	which	won	a	Computable award.  </p>
                                    </mat-card-content>
                                    <mat-card-actions class="action-buttons">

                                    </mat-card-actions>
                                  </mat-card>

                                              <mat-card>
                                    <mat-card-header>

                                      <mat-card-title class="title">Belgium, Netherlands, Luxembourg Research Lead</mat-card-title>
                                      <mat-card-subtitle>IBM CAS and University Programs, 2012-2015</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content>
                                      <p>
                                Responsible	for	the	the	CrowdTruth (Teach	Watson) research	project,	in	which	IBM
(Watson	Lab	and	BNL	CAS)		and	VU	Amsterdam	collaborated	to	explore	crowd- and
nichesourcing	for	the	training	of	the	medical	Watson. </p>
                                    </mat-card-content>
                                    <mat-card-actions class="action-buttons">

                                    </mat-card-actions>
                                  </mat-card>
                                    <mat-card>
                                         <mat-card-header>
                                      <mat-card-title class="title">Senior Consultant</mat-card-title>
                                      <mat-card-subtitle>IBM Smarter Planet Delivery, 2008-2012</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content>
                                      <p>
                               Implementation	and	design	of	the	first	IBM	Benelux	Smarter	Planet	projects:	Smart
Grid/Smart	Energy,	Telematics	(remote	truck	monitoring	and	e-Call)	and	Flood
Control	(remote	sensing	and	anomaly	detection	on	the	Dutch	levee	system).</p>
                                    </mat-card-content>
                                    <mat-card-actions class="action-buttons">

                                    </mat-card-actions>
                                  </mat-card>



                                </div>
                        </mat-card>

                        <mat-card fxLayout fxLayoutAlign="space-between">
                                                            <div  fxLayout="column" fxLayoutGap="30px" fxFlex="1 1 auto"> <!--*ngFor="let article of mArticles"-->

                                <img src="assets/img/professional/DaimlerChrysler.png"  height="50px"  width="220px" />

                                    <mat-card   >
                                    <mat-card-header>

                                      <mat-card-title class="title">Team Leader Compass</mat-card-title>
                                      <mat-card-subtitle>DaimlerChrysler CAC, 2005-2008</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content>
                                      <p>
                                Part	of	the	implementation	of	the	Mercedes-Benz	Kontakt	system,	for	remote
diagnosis	and	the	implementation	of	the	Mercedes-Benz	Roadside	Assistance
projects	(overflow	&	technician	monitoring).</p>
                                    </mat-card-content>
                                    <mat-card-actions class="action-buttons">

                                    </mat-card-actions>
                                  </mat-card>

                                </div>
                        </mat-card>

                  </mat-tab>
                  <mat-tab label="Advisory">

                      <mat-card fxLayout fxLayoutAlign="space-between">
                                <div fxLayout="column" fxLayoutGap="30px" fxFlex="1 1 auto"> <!--*ngFor="let article of mArticles"-->
                                <img src="assets/img/board/tudelft.png"  height="50px;" width="100px" />
                                         <mat-card>
                                    <mat-card-header>

                                      <mat-card-title class="title">Member Advisory Board Computer Science</mat-card-title>
                                      <mat-card-subtitle>2019 to date</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content>
                                      <p>
                                      </p>

                                    </mat-card-content>
                                    <mat-card-actions class="action-buttons">
                                    </mat-card-actions>
                                  </mat-card>

                                </div>
                        </mat-card>

                        <mat-card fxLayout fxLayoutAlign="space-between">
                                <div fxLayout="column" fxLayoutGap="30px" fxFlex="1 1 auto"> <!--*ngFor="let article of mArticles"-->
                                <img src="assets/img/board/maastricht.png"  height="50px;" width="200px" />
                                       <mat-card>
                                    <mat-card-header>

                                      <mat-card-title class="title">Member Advisory Board Knowledge Engineering & Data Science</mat-card-title>
                                      <mat-card-subtitle>2019 to date</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content>
                                      <p>
                                </p>
                                    </mat-card-content>
                                    <mat-card-actions class="action-buttons">

                                    </mat-card-actions>
                                  </mat-card>


                                </div>
                        </mat-card>

                        <mat-card fxLayout fxLayoutAlign="space-between">
                                <div fxLayout="column" fxLayoutGap="30px" fxFlex="1 1 auto"> <!--*ngFor="let article of mArticles"-->
                                <img src="assets/img/board/rug.png"  height="50px;" width="200px" />
                                        <mat-card   >
                                    <mat-card-header>

                                      <mat-card-title class="title">Member Advisory Board Artificial Intelligence</mat-card-title>
                                      <mat-card-subtitle>2016 to date</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content>
                                      <p>
                                 </p>
                                    </mat-card-content>
                                    <mat-card-actions class="action-buttons">

                                    </mat-card-actions>
                                  </mat-card>

                                </div>
                        </mat-card>
                      <mat-card fxLayout fxLayoutAlign="space-between">


                    </mat-card>

                      </mat-tab>
                  <mat-tab label="Education">
                      <mat-card fxLayout fxLayoutAlign="space-between">
                                <div fxLayout="column" fxLayoutGap="30px" fxFlex="1 1 auto"> <!--*ngFor="let article of mArticles"-->
                                <img src="assets/img/education/maastricht.png"  height="50px;" height="50px;" width="200px" />

                                    <mat-card>
                                    <mat-card-header>

                                      <mat-card-title class="title">Artificial Intelligence</mat-card-title>
                                      <mat-card-subtitle>M.Sc., 2000-2005</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content>
                                      <p>
                                      </p>

                                    </mat-card-content>
                                    <mat-card-actions class="action-buttons">
                                    </mat-card-actions>
                                  </mat-card>

                                </div>
                        </mat-card>

                        <mat-card fxLayout fxLayoutAlign="space-between">
                                <div fxLayout="column" fxLayoutGap="30px" fxFlex="1 1 auto"> <!--*ngFor="let article of mArticles"-->
                                <img src="assets/img/education/baylor.png"  height="50px;" height="50px;" width="150px" />

                                    <mat-card>
                                    <mat-card-header>

                                      <mat-card-title class="title">Computer Science & Mathematics</mat-card-title>
                                      <mat-card-subtitle>Exchance student, 2003</mat-card-subtitle>
                                    </mat-card-header>
                                    <mat-card-content>
                                      <p>
                                </p>
                                    </mat-card-content>
                                    <mat-card-actions class="action-buttons">

                                    </mat-card-actions>
                                  </mat-card>


                                </div>
                        </mat-card>
                      <mat-card fxLayout fxLayoutAlign="space-between">


                    </mat-card></mat-tab>
                  <!-- mat-tab label="Adventure">from russia with love</mat-tab -->
            </mat-tab-group></mat-grid-tile>
  </mat-grid-list>
</div>
