import { Component, OnInit } from '@angular/core';
import {BreakpointObserver, Breakpoints, BreakpointState} from "@angular/cdk/layout";
import dataJson from '../../assets/content/press.json';
import {Observable} from "rxjs";
import {BehaviorSubject} from "rxjs";
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.scss']
})
export class PressComponent implements OnInit {

    public results1$ = false;
  public results2$;
  Press: any = dataJson;


  selectable: boolean = true;

  tags = [
    { name: 'Personal', selected:true },
    { name: 'Watson', selected:true },
    { name: 'Pharma', selected:true },
    { name: 'Internet of Things', selected:true }

  ];

  selectedTags2: any[] = Object.assign([], this.tags);



  selectedTags: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(Object.assign([], this.tags));
  public selectedTags$ = this.selectedTags.asObservable();

  isSelected(tag: any): boolean {
    /*const index = this.selectedTags.indexOf(tag);
    var isSelected = index>=0;
    console.log(isSelected);
    return isSelected;*/
    return tag.selected;
  }


  toggleTag(tag: any): void {
    let index = this.selectedTags2.indexOf(tag);

    if (tag.selected) {
        this.selectedTags2.splice(index, 1);
        tag.selected=false;
    } else {
        this.selectedTags2.push(tag);
        tag.selected=true;
    }
  }


  constructor(private breakpointObserver: BreakpointObserver, private titleService: Title)
  {
    }

  ngOnInit() {
    this.breakpointObserver
        .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.results1$ = true;
            console.log(
                'Matches small viewport or handset in portrait mode'
            );
          }
        });

    this.titleService.setTitle('Robert-Jan Sips | Press coverage');

  }
}
