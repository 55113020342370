    <div class="md-fab-bottom-right3">
        <a  routerLink="" mat-fab  aria-label="" color="primary">
              <mat-icon>arrow_back</mat-icon>
        </a>
      </div>


<div style="width:100%; height:calc(100% - 64px); overflow:scroll;">
  <mat-grid-list cols="{{this.results1$?1:4}}" rowHeight="fit" style="width:100%; height:100%; overflow:scroll;">
      <mat-grid-tile *ngIf="this.results1$==false" [colspan]="1" [rowspan]="1" [style.background]="'grey'">

            <!--nav aria-label="breadcrumb" style="position:absolute; top:0px; left:50px;">
                  <ul class="breadcrumb">
                    <li class="breadcrumb-item" aria-current="page"><a routerLink="">Robert-Jan Sips</a></li>
                     <li class="breadcrumb-item active" aria-current="page">Talks</li>

                  </ul>
                </nav-->
           <table style="width:50%;">
              <tr><td><img src="assets/img/robert-jan.png" width="100%" alt="Robert-Jan Sips" /></td></tr>
              <tr><td><h1>Robert-Jan Sips</h1></td></tr>
              <tr><td>I am a regular speaker and lecturer on AI, if you're interested, get in touch!</td></tr>
          </table>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="this.results1$?1:3" [rowspan]="1" >
              <div fxLayout="column" fxLayoutGap="32px" style="width:100%; height:100%; overflow:scroll; padding-top:50px; margin-left:50px;">
            <mat-chip-list [(ngModel)]="tags" multiple>
                <mat-chip *ngFor="let tag of tags" selectable="true"
                         [selected]="tag.selected"
                         (click)="toggleTag(tag)"
                        class="{{isSelected(tag) ? 'mat-chip-selected' : ''}}">
                  {{tag.name}}&nbsp;
                  <mat-icon>{{isSelected(tag) ? "close" : "add"}}</mat-icon>
                </mat-chip>

              </mat-chip-list>

            <mat-card *ngFor="let talk of Talks |  filter:selectedTags2:'tags' " class="example-card"  > <!--*ngFor="let article of mArticles"-->

               <mat-card-header>
                    <img mat-card-avatar *ngIf="talk.image" src="{{talk.image}}"  />

                    <mat-expansion-panel class="mat-elevation-z0" >
                        <mat-expansion-panel-header style="text-align:left; flex:0;">

                            <mat-card-title class="title">{{talk.title}}</mat-card-title>

                        </mat-expansion-panel-header>
                       {{talk.description}}
                      </mat-expansion-panel>
               </mat-card-header>
                <mat-card-content >
                    <div class="flex-container"
                        fxLayout="row"
                        fxLayoutAlign="space-between center"
                    >
                      <div class="flex-item">
                         Full article: <a mat-button href="{{talk.link}}">{{talk.reference}}</a>
                      </div>
                      <div class="flex-item">
                        {{talk.tags.join(", ")}}
                      </div>

                    </div>



                </mat-card-content>

              </mat-card>

          </div>
      </mat-grid-tile>
  </mat-grid-list>
</div>



