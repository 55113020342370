<mat-toolbar color="primary">  <div style="position:fixed; right:50%;" ><h1>Robert-Jan Sips</h1></div>  <div style="position:fixed; right:20px;" >

  <a mat-icon-button class="btn-linkedin" target="new" href="http://www.linkedin.com/in/rsips"><i class="fab fa-linkedin-in fa-2x"></i></a>


        <a  mat-fab  aria-label="" (click)="onToggleFab()" color="primary">
              <mat-icon [@rotateInOnEnter]>email</mat-icon>
        </a>
          <!--a *ngIf="!isHamburguer"  mat-fab  aria-label=""  (click)="onToggleFab()" color="primary">
             <mat-icon [@rotateInOnEnter] >close</mat-icon>
        </a-->
      </div>
</mat-toolbar>


	<router-outlet>  </router-outlet>

