import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MainviewComponent } from './mainview/mainview.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { NavFullscreenComponent } from './nav-fullscreen/nav-fullscreen.component';
import { AboutmeComponent } from './aboutme/aboutme.component';
import { PressComponent } from './press/press.component';
import { PublicationsComponent } from './publications/publications.component';
import { TalksComponent } from './talks/talks.component';
import { MatTabsModule} from "@angular/material/tabs";
import { FlexLayoutModule } from '@angular/flex-layout';
import { MarkdownModule } from 'ngx-markdown';
import {HttpClientModule} from "@angular/common/http";
import {ContactService} from "./contact.service";
import { ContactformComponent } from './contactform/contactform.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatChipsModule} from "@angular/material/chips";
import { FilterPipe } from './filter.pipe';
import {MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    MainviewComponent,
    NavFullscreenComponent,
    AboutmeComponent,
    PressComponent,
    PublicationsComponent,
    TalksComponent,
    ContactformComponent,
    FilterPipe
  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ScullyLibModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
      MatTabsModule,
      HttpClientModule,
    MarkdownModule.forRoot({ loader: HttpClientModule }),
      FormsModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatExpansionModule,
      MatChipsModule,
      MatBottomSheetModule,
      MatToolbarModule


  ],
  providers: [ContactService],
  bootstrap: [AppComponent],
  entryComponents: [MainNavComponent, MatFormFieldModule]

})
export class AppModule { }
