import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainNavComponent} from "./main-nav/main-nav.component";
import {MainviewComponent} from "./mainview/mainview.component";
import {NavFullscreenComponent} from "./nav-fullscreen/nav-fullscreen.component";
import {AboutmeComponent} from "./aboutme/aboutme.component";
import {PublicationsComponent}  from "./publications/publications.component";
import {PressComponent}  from "./press/press.component";
import {TalksComponent}  from "./talks/talks.component";


const routes: Routes = [
  { path: '', component: MainviewComponent, pathMatch: 'full', data: {state:  'home'}  },
  { path: 'about-me', component: AboutmeComponent, data: {state:  'about'} },
  { path: 'publications', component: PublicationsComponent, data: {state:  'publications'}},
  { path: 'press', component: PressComponent, data: {state:  'press'}},
  { path: 'talks', component: TalksComponent, data: {state:  'talks'}}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
