<div style="width:100%; height:100%; overflow:scroll;">
  <mat-grid-list cols="4" rowHeight="fit" style="width:100%; height:100%; overflow:scroll;">
       <mat-grid-tile [colspan]="2" [rowspan]="1" [style.background]="'royalblue'" [style.color]="'white'">

          <table style="width:33%;">
              <tr><td><img src="assets/img/robert-jan.png" width="100%" alt="Robert-Jan Sips" /></td></tr>
              <tr><td><h1>Get in touch</h1></td></tr>
              <tr><td></td></tr>
          </table>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="1" style="width:100%; height:100%; overflow:scroll;">
          <div fxLayout="column" fxLayoutGap="32px">
                      <app-contactform></app-contactform>
          </div>
      </mat-grid-tile>
  </mat-grid-list>
</div>






