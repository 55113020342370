import { Component, OnInit } from '@angular/core';
import {BreakpointObserver, Breakpoints, BreakpointState} from "@angular/cdk/layout";
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-aboutme',
  templateUrl: './aboutme.component.html',
  styleUrls: ['./aboutme.component.scss']
})

export class AboutmeComponent implements OnInit {
  public results1$ = false;
  public results2$;

  constructor(private breakpointObserver: BreakpointObserver, private titleService: Title) {}

  ngOnInit() {
    this.breakpointObserver
        .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
              this.results1$ = true;
              console.log(
                  'Matches small viewport or handset in portrait mode'
              );
          }
        });

    this.titleService.setTitle('Robert-Jan Sips | About me');


  }


}
